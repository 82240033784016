export const UPDATE_CLIENT_FEATURE_MUTATION = gql`
  mutation UpdateClientFeature($id: Int!, $input: UpdateClientFeatureInput!) {
    updateClientFeature(id: $id, input: $input) {
      id
      enabled
    }
  }
`

export const CREATE_CLIENT_FEATURE_MUTATION = gql`
  mutation CreateClientFeature($input: CreateClientFeatureInput!) {
    createClientFeature(input: $input) {
      id
      enabled
    }
  }
`

export const DELETE_CLIENT_FEATURE_MUTATION = gql`
  mutation DeleteClientFeature($id: Int!) {
    deleteClientFeature(id: $id) {
      id
    }
  }
`

export const DELETE_CLIENT_FEATURE_BY_FEATURE_CLIENT_ID_MUTATION = gql`
  mutation DeleteClientFeatureByClient($featureId: Int!, $clientId: Int!) {
    deleteClientFeatureByClient(featureId: $featureId, clientId: $clientId)
  }
`
